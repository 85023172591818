<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6" lg="4" v-if="false">
                    <Validation-provider name="unihall_quota_id" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('unihall_quota')">
                            <unihall-quota-selectbox :disabled="disabled" v-model="formData.unihall_quota_id"
                                :validate-error="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <Validation-provider name="currency" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('currency')">
                            <b-form-select :disabled="disabled" v-model="formData.currency"
                                :class="errors[0] ? 'is-invalid' : ''" :options="[
                                    { text: 'TRY', value: 'TRY' },
                                    { text: 'USD', value: 'USD' },
                                ]" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <Validation-provider name="installment" rules="required|numeric" v-slot="{ errors }">
                        <b-form-group :label="$t('installment')">
                            <b-form-input :disabled="disabled" v-model="formData.installment"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <Validation-provider name="price" rules="required|double" v-slot="{ errors }">
                        <b-form-group :label="$t('price')">
                            <b-form-input :disabled="disabled" v-model="formData.price" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button class="px-5" :variant="formType == 'delete' ? 'danger' : 'primary'" @click="createForm"
                    :disabled="loading">
                    {{ buttonText }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>
// Services
import UnihallQuotaPriceService from "@/services/UnihallQuotaPriceService";

export default {
    name: "UnihallQuotaPrice",
    props: {
        formType: {
            type: String,
            required: true,
        },
        formId: {
            type: [Number, String],
            default: -1,
        },
        value: {
            type: Object,
            default: () => { },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    watch: {
        formType: {
            handler() {
                this.$refs.formModalValidate.reset();
            },
        },
    },
    computed: {
        formData: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
                return val;
            },
        },
        buttonText() {
            if (this.formType === "show") {
                return this.$t("close");
            } else if (this.formType === "add") {
                return this.$t("save");
            } else if (this.formType === "update") {
                return this.$t("update");
            } else if (this.formType === "delete") {
                return this.$t("delete");
            }
            return "";
        },
    },
    methods: {
        async createForm() {
            if (this.formType === "show") {
                this.$emit("close");
                return;
            }
            const valid = await this.$refs.formModalValidate.validate();
            if (!valid && this.formType !== "delete") {
                return;
            }
            this.loading = true;

            let response = null;
            let result = null;

            this.formData.unihall_quota_id = this.formId;
            this.formData.price = Number(
                Number(this.formData.price).toFixed(2)
            );

            switch (this.formType) {
                case "add":
                    response = await UnihallQuotaPriceService.store(
                        this.formData
                    )
                        .catch((e) => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                    break;

                case "update":
                    response = await UnihallQuotaPriceService.update(
                        this.formData.id,
                        {
                            unihall_quota_id: this.formData.unihall_quota_id,
                            installment: this.formData.installment,
                            price: this.formData.price,
                            currency: this.formData.currency,
                        }
                    )
                        .catch((e) => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                    break;

                case "delete":
                    result = await this.$swal.fire({
                        text: this.$t("are_you_sure_to_delete"),
                        showCancelButton: true,
                        confirmButtonText: this.$t("yes"),
                        cancelButtonText: this.$t("no"),
                    });
                    if (result.isConfirmed) {
                        response = await UnihallQuotaPriceService.del(
                            this.formData.id
                        )
                            .catch((e) => {
                                this.showErrors(
                                    e,
                                    this.$refs.formModalValidate
                                );
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    } else {
                        return
                    }
                    break;

                default:
                    break;
            }

            if (!response) {
                this.$toast.error(this.$t("something_went_wrong"));
                return;
            }

            if (response?.data?.success) {
                this.formClear();
                this.$refs.formModalValidate.reset();
                if (this.formType === "update" || this.formType === "delete") {
                    this.$emit("close");
                }
            }
            this.$toast.success(this.$t("api." + response.data.message));
        },
        formClear() {
            this.formData = {};
        },
    },
};
</script>
<style lang=""></style>
